import { Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ValidationService } from 'src/app/_shared/validation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-password-dialog',
  templateUrl: './create-password.dialog.html',
  styleUrls: ['./create-password.dialog.scss'],
})
export class CreatePasswordDialog implements OnInit {
  hide = true;
  hide1 = true;
  createPasswordForm;
  constructor(
    public dialogRef: MatDialogRef<CreatePasswordDialog>,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  passwordConfirming(c: AbstractControl): { mismatch: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return { mismatch: true };
    }
    return null;
  }

  ngOnInit() {
    this.buildForm(this.data.configs);
  }

  buildForm(configs) {
    this.createPasswordForm = this.fb.group(
      {
        password: ['', ValidationService.compose(configs, 'password')],
        confirmPassword: ['', ValidationService.compose(configs, 'password')],
      },
      { validator: this.passwordConfirming }
    );
  }

  createPassword() {
    if (this.createPasswordForm.invalid) {
      this.createPasswordForm.markAllAsTouched();
      return;
    }
    let legalEntityId = this.route.snapshot.queryParamMap.get('legalEntityId');
    this.dialogRef.close({
      payload: { ...this.createPasswordForm.value, email: this.data.email, legalEntityId },
    });
  }
}
